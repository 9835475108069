import React, { useState } from "react";
import { motion } from "framer-motion";
import "./GetThe.css";

const GetheApp = () => {

  const faqs = [
    {
      question: "How Moii Wash work?",
      answer: "Our app simplifies the process of getting your car washed or tinted. Simply download the app, choose your preferred service, select a convenient time slot, and our professionals will come to your location to get the job done."
    },
    {
      question: 'What services do you offer for car washing?',
      answer: "Our app simplifies the process of getting your car washed or tinted.Simply download the app, choose your preferred service, select a convenient time slot,and our professionals will come to your location to get the job done"
    },
    {
      question: 'Do you use eco-friendly products for car washing?',
      answer: "Our app simplifies the process of getting your car washed or tinted.Simply download the app, choose your preferred service, select a convenient time slot,and our professionals will come to your location to get the job done"
    },

    {
      question: 'How long does a car wash or tinting service take?',
      answer: "Our app simplifies the process of getting your car washed or tinted.Simply download the app, choose your preferred service, select a convenient time slot,and our professionals will come to your location to get the job done"
    },
    {
      question: 'Is the payment process secure?',
      answer: "Our app simplifies the process of getting your car washed or tinted.Simply download the app, choose your preferred service, select a convenient time slot,and our professionals will come to your location to get the job done"
    },
  ]
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const animationVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <section className='services_page'>
      <motion.div className="service_heading" initial={'hidden'} animate={'visible'} variants={animationVariants}>
        <h1>FAQ</h1>
        <div className="faq-with-a">
          {faqs?.map((faq, index) => (
            <div className="faq-subdiv" style={{borderBottom: index !== 4 ? '1px solid rgba(233, 237, 240, 1)' : 'none'}}  key={index}>
              <div className="faq-question" onClick={() => toggleAnswer(index)} >
                <p className="questions" >
                  {faq.question}
                </p>
                <p style={{ marginLeft: "auto" , fontSize:"30px" }}>{activeIndex === index ? '-' : '+'}</p>
              </div>
              <p className="answers" style={{ display: activeIndex === index ? 'block' : 'none' }}>
                {faq.answer}
              </p>
            </div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default GetheApp;