import React from "react";
import "../privecy.css";

function TermsContitionPage() {


  const handleEmailClickRiyas = () => {
    window.location.href = "Info@moii.ae"; 
  };
  return (
    <div className="privecy">
      <div className="privecy_heading">
        <h1 className="terms">Terms & Conditions</h1>
        <p>MoiiWash Mobile Car wash service app</p>
      </div>
      <div className="privecy_main">
        {/* lorem */}
        <p>
          These terms and conditions outline the rules and regulations for the
          use of the MoiiWash mobile application.
        </p>
        <p>
          By accessing this application, we assume you accept these terms and
          conditions. Do not continue to use MoiiWash if you do not agree to
          take all of the terms and conditions stated on this page.
        </p>
        {/* heading */}
        <h2>Cookies</h2>

        {/* lorem */}
        <p>
          We employ the use of cookies. By accessing MoiiWash, you agreed to use
          cookies in agreement with the MoiiWash's Privacy Policy.
        </p>

        {/* heading */}
        <h2>License</h2>

        {/* lorem */}
        <p>
          Unless otherwise stated, MoiiWash and/or its licensors own the
          intellectual property rights for all material on MoiiWash. All
          intellectual property rights are reserved. You may access this from
          MoiiWash for your own personal use subjected to restrictions set in
          these terms and conditions.
        </p>

        {/* heading */}
        <h2>Content Liability</h2>

        {/* lorem */}
        <p>
          We shall not be held responsible for any content that appears on your
          application. You agree to protect and defend us against all claims
          that are rising on your application. No link(s) should appear on any
          application that may be interpreted as libelous, obscene, or criminal,
          or which infringes, otherwise violates, or advocates the infringement
          or other violation of, any third party rights.
        </p>

        {/* heading */}
        <h2>Reservation of Rights</h2>

        {/* lorem */}
        <p>
          We reserve the right to request that you remove all links or any
          particular link to our application. You approve to immediately remove
          all links to our application upon request. We also reserve the right
          to amend these terms and conditions and its linking policy at any
          time. By continuously linking to our application, you agree to be
          bound to and follow these linking terms and conditions.
        </p>

        {/* heading */}
        <h2>Removal of Links from our Application</h2>

        {/* lorem */}
        <p>
          If you find any link on our application that is offensive for any
          reason, you are free to contact and inform us at any moment. We will
          consider requests to remove links but we are not obligated to or so or
          to respond to you directly.
        </p>
        

       <h2>Contact Us</h2>
       <p  onClick={handleEmailClickRiyas} >Info@moii.ae</p>
      </div>
    </div>
  );
}

export default TermsContitionPage;
