import React from "react";
import "../privecy.css";

function PrivecyPolicy() {
  return (
    <div className="privecy">
      <div className="privecy_heading">
        <h1>Privacy policy  </h1>
        <p>MoiiWash Mobile Car wash service app</p>
      </div>
      <div className="privecy_main">
        {/* lorem */}
        <p>
          MoiiWash is committed to protecting the privacy of its users. This
          Privacy Policy outlines how we collect, use, and safeguard your
          personal information
        </p>
        {/* heading */}
        <h2>Information We Collect</h2>

        {/* lorem */}
        <p>
          We may collect personal information such as your name, contact
          details, and location to provide efficient carwash services.
          Additionally, we gather non-personal data like device information for
          app improvement.
        </p>

        {/* heading */}
        <h2>Use of Information</h2>

        {/* lorem */}
        <p>
          Personal information is used for carwash bookings, notifications, and
          customer support. Non-personal data helps us enhance app performance.
          We do not sell or share your information with third parties for
          marketing purposes.
        </p>

        {/* heading */}
        <h2>Location Data</h2>

        {/* lorem */}
        <p>
          MoiiWash utilizes location services to offer tailored carwash options.
          Your location data is solely used for this purpose, and we do not
          store historical location information.
        </p>

        {/* heading */}
        <h2>Security Measures</h2>

        {/* lorem */}
        <p>
          We implement robust security measures to protect your personal
          information from unauthorized access, disclosure, or alteration.
          Regular security audits are conducted to ensure compliance.
        </p>
        <p>
          User Control: MoiiWash provides users with control over their data.
          You can update, modify, or delete your personal information through
          the app settings. Opt-out options for promotional communications are
          also available.
        </p>

        {/* heading */}
        <h2>Third-Party Services</h2>

        {/* lorem */}
        <p>
          MoiiWash may integrate with third-party services for enhanced
          features. These services adhere to their privacy policies, and we
          encourage users to review them.
        </p>
        <p>
          Children's Privacy: MoiiWash is not intended for individuals under the
          age of 13. We do not knowingly collect personal information from
          children. If we discover such data, we will promptly delete it.
        </p>

        {/* heading */}
        <h2>Updates to Privacy Policy</h2>

        {/* lorem */}
        <p>
          MoiiWash may update this Privacy Policy as necessary. Users will be
          notified of any significant changes. Continued use of the app after
          modifications implies acceptance of the updated policy.
        </p>

        {/* heading */}
        <h2>Contact Information</h2>

        {/* lorem */}
        <p>
          For inquiries or concerns about this Privacy Policy, contact MoiiWash
          at <span style={{color:' rgb(33, 33, 85)',fontWeight:'bold'}}>Moiwashuae@gmail.com</span>
        </p>
      </div>
    </div>
  );
}

export default PrivecyPolicy;
