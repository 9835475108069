import React, { useEffect, useRef } from 'react'
import './screen.css'
import { motion, useAnimation, useInView } from "framer-motion";
import { animationVariants } from "../../style/Motion";
import bgimage from "../../assets/images/bgimage.jpg"


function ScreenShort() {
  const controll = useAnimation();
  const ref = useRef("");
  const usenView = useInView(ref);

  useEffect(() => {
    if (usenView) {
      controll.start("visible");
    }
  }, [usenView, controll]);
  return (
    <div className='screenShortPage' >
      {/* <div className="container"> */}
        <motion.div className="screen-short_heading"
          initial={'hidden'}
          animate={controll}
          variants={animationVariants}
          ref={ref}
          style={{ backgroundImage: `url(${bgimage})` }}
        >

          <div className="scr_heading">
            <h1>Detail The Difference!</h1>
            <p className='smalltext5'>Moii, Made with heartfelt dedication in Dubai</p>
          </div>
        </motion.div>

      {/* </div> */}
    </div>
  )
}

export default ScreenShort