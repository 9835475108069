import React from "react";
import "./footer.css";
import Logo from "../../assets/images/Moii Wash Brand.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

const Footer = () => {

  const handlePhoneClick = () => {
    window.location.href = "tel:+971507075135";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:Info@moii.ae";
  }

  return (
    <footer>
      <div className="footer">
        <div className="footer__logo">
          <div className="footer__logo__image">
            <div className="footer_top">
              <img src={Logo} alt="Logo" />
            </div>

          </div>

          <div className="footer_bottom">
            <div className="social-icons" >
              <button className="iconbtn">
                <a href="https://www.instagram.com/moiiwash?igshid=OWJ3Mmw5a25udjZ1&utm_source=qr" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </a>
              </button>
              <button className="iconbtn">
                <a href="https://api.whatsapp.com/send?phone=+971507075135&text=Hello%20,%20moii%20wash!" target="_blank" rel="noopener noreferrer">
                  <WhatsAppIcon />
                </a>
              </button>
              <button className="iconbtn">
                <a href="https://www.linkedin.com/company/moii-car-wash/" target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon />
                </a>
              </button>
            </div>
            <p className="footertext">© 2024Moiiwash</p>
          </div>

        </div>
        {/* <div className="footer__list">
        <h3>Navigation</h3>
        <ul>
        <Link to={"/"}>

          <li>Home</li>
          </Link>
          <li>About Us</li>
          <li>Our Facilities</li>
        </ul>
      </div> */}
        <div className="footer__list">
          <h3>Index</h3>
          <ul>
            <Link to={"/privecy"}>
              {" "}
              <li>Privacy And Terms</li>
            </Link>
            <Link to={"/privecy"}>
              {" "}
              <li>Privacy Policy</li>
            </Link>
            <Link to={"/terms"}>
              {" "}
              <li>Terms & Conditions</li>
            </Link>

          </ul>
        </div>
        <div className="footer__list">
          <h3>Contact Us</h3>
          <ul>
            <li onClick={handleEmailClick}>Info@moii.ae</li>
            <li onClick={handlePhoneClick}>+971 0507075135</li>
          </ul>
        </div>
      </div>
    </footer >
  );
};

export default Footer
