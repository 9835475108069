import React, { useEffect, useState } from "react";
import Home from "./page/Home";
import { useGlobalData } from "./context/LanguageContext";
import RequestPopup from "./components/RequestPopup/RequestPopup";
import { createPortal } from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import PrivecyPolicy from './page/PrivecyPolicy'
import Loading from "./components/Loading/Loading";
import TermsContitionPage from "./page/TermsContitionPage";
import DownLoadPage from "./page/Download";

function App() {
  const { languageChange, popus, setPopup } = useGlobalData();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadingTime = setTimeout(() => {
      setLoading(true);
    }, 2000);

    // const timeOut = setTimeout(() => {
    //   setPopup(true);
    // }, 9000);

    return () => {
      clearTimeout(loadingTime)
      // clearTimeout(timeOut)
    }
  }, [])


  return (
    <div className={`${languageChange && "arabic"}`}>
      <BrowserRouter>
        {loading === false ? (
          <Loading />
        ) : (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privecy" element={<PrivecyPolicy />} />
              <Route path="/terms" element={<TermsContitionPage />} />
              <Route path="/app_link" element={<DownLoadPage isOpen={true} setIsOpen={() => { }} />} />
            </Routes>
            <Footer />
          </>
        )}
      </BrowserRouter>
      {popus && createPortal(<RequestPopup close={setPopup} />, document.body)}
    </div>
  );
}

export default App;
