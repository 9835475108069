import React, { useEffect, useRef } from "react";
import "./About.css";
import { motion, useAnimation, useInView } from "framer-motion";
import { animationVariants } from "../../style/Motion";
import mobileApp from '../../assets/new-about_bgimg2.png'
import googlePlay from '../../assets/play.png'
import appstore from '../../assets/appstore.svg'
import AppleIcon from "@mui/icons-material/Apple";

const About = () => {
  const controll = useAnimation();
  const ref = useRef("");
  const usenView = useInView(ref);

  useEffect(() => {
    if (usenView) {
      controll.start("visible");
    }
  }, [usenView, controll]);
  return (
    <section className="about_us_page" >
      <div className="container">
        <motion.div className="about_us"
          ref={ref} initial={'hidden'}
          animate={controll}
          variants={animationVariants}
        >
          <div className="about_bgi" style={{ backgroundImage: `url(${mobileApp})` }}></div>
          <div className="about_left">
            <div className="about_contant">
              <h1>Effortless Car Care, Right at Your Fingertips!</h1>
              <p className="text-small4">Download the Moii wash app on iOS / Android phones for a seamless car ownership experience.
                Track all your bookings and get rewarded for every transaction.
              </p>
              <div className="btn_div">
                <div className="btn_imgdiv">
                  <a href="https://play.google.com/store/apps/details?id=com.moiiwash.moii" target="_blank" rel="noopener noreferrer">
                    <img className="btn_img" src={googlePlay} alt="" />
                  </a>
                </div>
                <div className="btn_imgdiv">
                  <a href="https://apps.apple.com/app/moii-wash/id6478136914" target="_blank" rel="noopener noreferrer">
                    <img className="btn_img" src={appstore} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

        </motion.div>
      </div>
    </section>
  );
};

export default About;
