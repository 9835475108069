import React, { useRef } from 'react';
import Hero from '../components/Hero/Hero';
import About from '../components/AboutUs/About';
import Service from '../components/Services/Service';
import Material from '../components/Materials/Material';
import UseApp from '../components/UseApp/UseApp';
import WhatWeDo from '../components/WhatWeDo/WhatWeDo';
import ScreenShort from '../components/ScreenShort/ScreenShort';
import GetheApp from '../components/GettheApp/GettheApp';
import New from '../components/New/New';
import Reviews from '../components/Reviews/Reviews';

const Home = () => {
  const heroRef = useRef(null);

  const scrollToHero = () => {
    heroRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <New scrollToHero={scrollToHero} />
      <div ref={heroRef}>
        <Hero />
      </div>
      <Reviews />
      <GetheApp />
      <About />
      <ScreenShort />
    </>
  );
};

export default Home;
