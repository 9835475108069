import React, { useEffect, useState, useRef } from "react";
import "./Reviews.css";
// import AboutImage from '../../assets/aboutImage.jpg'
import { motion, useAnimation, useInView } from "framer-motion";
import { animationVariants } from "../../style/Motion";
import nextBtn from '../../assets/images/chevron_right1.svg'
import previousBtn from '../../assets/images/chevron_left1.svg'
import quotesign from '../../assets/images/Vector.png'
import verify from '../../assets/images/verify2.png'
const Reviews = () => {
    const controll = useAnimation();
    const ref = useRef("");
    const usenView = useInView(ref);
    const reviews = [
        {
            reviewerImage: 'reviews-img1.jpg',
            reviewer: "Floyd Miles",
            review: "I recently tried out Moiiwash for a carwash, and I'm thoroughly impressed! Booking was a breeze, and the team arrived right on time. They did an incredible job cleaning both the interior and exterior of my car. It's never looked better! Plus, their eco-friendly products are a huge plus. Highly recommend!"
        }, {
            reviewerImage: 'reviews-img1.jpg',
            reviewer: "Floyd Miles",
            review: "I recently tried out Moiiwash for a carwash, and I'm thoroughly impressed! Booking was a breeze, and the team arrived right on time. They did an incredible job cleaning both the interior and exterior of my car. It's never looked better! Plus, their eco-friendly products are a huge plus. Highly recommend!"
        }, {
            reviewerImage: 'reviews-img1.jpg',
            reviewer: "Floyd Miles",
            review: "I recently tried out Moiiwash for a carwash, and I'm thoroughly impressed! Booking was a breeze, and the team arrived right on time. They did an incredible job cleaning both the interior and exterior of my car. It's never looked better! Plus, their eco-friendly products are a huge plus. Highly recommend!"
        }
    ]

    const [currentIndex, setCurrentIndex] = useState(0)

    const handleNext = (index) => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length)
    }
    const handlePrevious = (index) => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length)
    }
    const { reviewerImage, reviewer, review } = reviews[currentIndex]

    useEffect(() => {
        if (usenView) {
            controll.start("visible");
        }
    }, [usenView, controll]);
    return (
        <section className="about_us_page" >
            <div className="container">
                <motion.div className="reviews"
                    ref={ref} initial={'hidden'}
                    animate={controll}
                    variants={animationVariants}
                >
                    <p className="reviewshead">REVIEWS</p>
                    <div className="reviews_left">
                        <div className="reviews_contant">
                            <h1>Top-Notch Service!</h1>
                            <p>
                                {review}
                            </p>
                            <br/>
                            <p>
                                <img src={verify} className="verifyicon" alt="" />
                                {reviewer}
                            </p>

                        </div>
                        <div className="review_btndiv">
                            <div className="reviews_btn"><img className="reviews_btnimg" src={previousBtn} onClick={() => handlePrevious(currentIndex)} alt="" /></div>
                            <div className="reviews_btn"><img className="reviews_btnimg" src={nextBtn} onClick={() => handleNext(currentIndex)} alt="" /></div>
                            {/* <button className="reviews_btn" onClick={() => handleNext(currentIndex)}>&#60;</button>
                            <button className="reviews_btn" onClick={() => handlePrevious(currentIndex)}>&#62;</button> */}
                        </div>
                    </div>
                    <div className="reviews_right"
                        style={{ backgroundImage: `url(${require(`../../assets/images/${reviewerImage}`)} )` }}
                    >
                        <img src={quotesign} className="quotesign" alt="" />
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default Reviews;
