import React, { useState } from "react";
import "./popup.css";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";


function RequestPopup({ close }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  
  const refresh=()=>{
    setName('')
    setEmail('')
    setNumber('')
    setMessage('')
    close(false)
  }

  
  // emali js
  const serviceId=''
  const templateId=''
  const publicKey=''

  const handleSubmit=(e)=>{
    e.preventDefault()
    const data={
        service_id:serviceId,
        template_id:templateId,
        user_id:publicKey,
        template_params:{
            from_name:name,
            from_email:email,
            from_number:number,
            to_name:"moiiwash",
            message:message
        }
    }
    
    try{
   if(number && email ){
    const respons=axios.post('https://api.emailjs.com/api/v1.0/email/send',data)
    toast.success("success full sended")
     refresh()
   }
   
    }catch(err){
    console.log(err);
    }
 }
  return (
    <div className="popup">
      <div className="popup_left"></div>
      <div className="popup_right">
        <span onClick={() => close(false)}>
          <CloseRoundedIcon/>
        </span>
        <h1>Request a Callback</h1>

        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="inputs">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="inputs">
            <input
              type="text"
              placeholder="Mobile Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
          <div className="inputs">
            <textarea
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="inputs">
            <button type="submit">Send Request</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RequestPopup;
