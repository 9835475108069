import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx'
import reportWebVitals from './reportWebVitals';

import LanguageContext from './context/LanguageContext.jsx'

ReactDOM.render(
  <React.StrictMode>
   <LanguageContext>
    <App />
    </LanguageContext>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();