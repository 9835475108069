import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";
import { AiFillApple } from 'react-icons/ai';
import { SiGoogleplay } from 'react-icons/si';
import Modal from 'react-modal';

const DownLoadPage = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectToAppStore = () => {
      if (isAndroid) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.moiiwash.moii";
        setIsOpen(false);
      } else if (isIOS) {
        window.location.href = "https://apps.apple.com/app/moii-wash/id6478136914";
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    redirectToAppStore();

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [setIsOpen]);

  const handleBackButton = () => {
    navigate('/');
  };

  const handleStoreRedirect = (store) => {
    if (store === 'google') {
      window.open("https://play.google.com/store/apps/details?id=com.moiiwash.moii", '_blank');
    } else if (store === 'apple') {
      window.open("https://apps.apple.com/app/moii-wash/id6478136914", '_blank');
    }
    setIsOpen(false); // Close modal after redirect
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    if (location.pathname === '/app_link') {
      navigate('/');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000 // Higher z-index to overlay other content
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '90%', // Limit maximum width
          width: '300px',
          minHeight: '30px', // Allow content to determine height
          maxHeight: '80vh', // Limit height to 80% of viewport height
          padding: '20px',
          textAlign: 'center'
        }
      }}
    >
      <h2 style={{ marginBottom: '25px' }}>Choose Store</h2>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ textAlign: 'center' }}>
          <AiFillApple
            size={50}
            style={{ cursor: 'pointer' }}
            onClick={() => handleStoreRedirect('apple')}
          />
          <p style={{ marginTop: '10px' }}>App Store</p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <SiGoogleplay
            size={50}
            style={{ cursor: 'pointer' }}
            onClick={() => handleStoreRedirect('google')}
          />
          <p style={{ marginTop: '10px' }}>Google Play</p>
        </div>
      </div>
    </Modal>
  );
};

export default DownLoadPage;
