import { createContext, useContext, useState } from "react";

export const WrapperContext = createContext(null);

function LanguageContext({ children }) {
  // contact popup
  const [popus, setPopup] = useState(false);
  
  const [languageChange, setLanguageChange] = useState(false);
  const languageHandler = () => {
    setLanguageChange((prev) => !prev);
  };
  return (
    <WrapperContext.Provider value={{ languageHandler, languageChange, popus, setPopup }}>
      {children}
    </WrapperContext.Provider>
  );
}

export default LanguageContext;

// Use context
export const useGlobalData = () => {
  const context = useContext(WrapperContext);
  if (!context) {
    throw new Error("useGlobalData must be used within a LanguageContextProvider");
  }

  return context;
};
