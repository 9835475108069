import React, { useState, useEffect } from 'react';
import './Card.css'; // Import CSS for styling

const Card = ({ cards }) => {
    const [selectedCard, setSelectedCard] = useState(0);
    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            setIsMobileView(screenWidth <= 768); // Adjust breakpoint as needed
        };
        handleResize(); // Initial check on component mount
        window.addEventListener('resize', handleResize); // Add resize event listener

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up on component unmount
        };
    }, []);

    const handleCardClick = (index) => {
        setSelectedCard(index); // Set the selected card index
    };

    if (isMobileView) {
        // Render individual pages for each card in mobile view
        return (
            <div className="card-container">
                {cards.map((card, index) => (
                    <div key={index} className="card-page">
                        <h2>{card.title}</h2>
                        {/* <img className='mobileview-image' src={card.imageUrl} alt="" /> */}
                        <div className='img-grandient' style={{ backgroundImage: `url(${card.imageUrl})` }}>
                        </div>
                        <ul>
                            {cards[index].service?.map((service) => (
                                <li>{service}</li>
                            ))}
                        </ul>
                        <p>{card.description}</p>
                    </div>
                ))}

                <a href="https://play.google.com/store/apps/details?id=com.moiiwash.moii" target="_blank" rel="noopener noreferrer">
                    <button className="blue_btn">
                        Try Now
                    </button>
                </a>
            </div>
        );
    }


    return (
        <div className="card-container">
            {/* Left Section: List of Cards */}
            <div className="left-section">
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className={`card-item ${selectedCard === index ? 'active' : ''}`}
                        onClick={() => handleCardClick(index)}
                    >
                        {card.title}
                    </div>
                ))}
                <a href="https://play.google.com/store/apps/details?id=com.moiiwash.moii" target="_blank" rel="noopener noreferrer">
                    <button className="blue_btn">
                        Try Now
                    </button>
                </a>
            </div>

            {/* Right Section: Display Detailed Card */}
            <div className="right-section">
                {selectedCard !== null && (
                    <div className="detailed-card">
                        <div
                            className="background-image"
                            style={{ backgroundImage: `url(${cards[selectedCard].imageUrl})` }}
                        />
                        <div className="card-details">
                            {/* <h2>{cards[selectedCard].title}</h2> */}
                            <p>{cards[selectedCard].description}</p>
                            <ul>
                                {cards[selectedCard].service?.map((service) => (
                                    <li>{service}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Card;
