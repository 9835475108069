import React from "react";
import { BeatLoader } from "react-spinners";
import './loading.css'
function Loading() {
  return (
    <div className='loading'>
      <BeatLoader color=" rgb(33, 33, 85)" loading />
    </div>
  );
}

export default Loading;
