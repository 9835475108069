import React, { useEffect, useRef } from "react";
import "./hero.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "framer-motion";
import { animationVariants } from "../../style/Motion";
import HeroMain from '../../assets/new4.jpg'
import Card from './Card';

const Hero = () => {

  const cardsData = [
    // {
    //   title: 'Quick Wash',
    //   imageUrl: HeroMain,
    //   description: 'External Wash Quick cleaning of the exterior to remove dirt, grime, and debris. Interior Cleaning Quick cleaning of the interior surfaces, including dashboard, door panels, and centre console.Tyre Polishing Quick Polishing tyres to give them a clean and glossy look',
    //   service: ['External Wash', 'Interior Vacuum', 'dashboard and Body Wax Polish', 'Tyre Polish']
    // },
    {
      title: 'Steamy Spa',
      imageUrl: HeroMain,
      description: 'External Wash Quick cleaning of the exterior to remove dirt, grime, and debris. Interior Cleaning Quick cleaning of the interior surfaces, including dashboard, door panels, and centre console. Tyre Polishing Quick Polishing tyres to give them a clean and glossy look',
      service: ['High Pressure steam', 'Tyre Polish', 'External Wash', 'Interior Vacuum']
    },
    {
      title: 'Premium Spa',
      imageUrl: HeroMain,
      description: 'External Wash Quick cleaning of the exterior to remove dirt, grime, and debris. Interior Cleaning Quick cleaning of the interior surfaces, including dashboard, door panels, and centre console. Tyre Polishing Quick Polishing tyres to give them a clean and glossy look',
      service: ['High Pressure steam', 'Dashboard and Body Wax Polish' , 'Tyre Polish', 'External Wash', 'Interior Vacuum']
    },
    {
      title: 'Window Regular Tinting',
      imageUrl: HeroMain,
      description: 'External Wash Quick cleaning of the exterior to remove dirt, grime, and debris. Interior Cleaning Quick cleaning of the interior surfaces, including dashboard, door panels, and centre console. Tyre Polishing Quick Polishing tyres to give them a clean and glossy look',
      service: ['One Year Warranty', 'Heat Rejection 10%', 'Made In USA']
    },
    {
      title: 'Window Nano Ceramic Tinting',
      imageUrl: HeroMain,
      description: 'External Wash Quick cleaning of the exterior to remove dirt, grime, and debris. Interior Cleaning Quick cleaning of the interior surfaces, including dashboard, door panels, and centre console. Tyre Polishing Quick Polishing tyres to give them a clean and glossy look',
      service: ['Two Year Warranty', 'Heat Rejection 50%', 'Made In GERMANY']
    },
  ];

  const controll = useAnimation();
  const ref = useRef("");
  const usenView = useInView(ref);

  useEffect(() => {
    if (usenView) {
      controll.start("visible");
    }
  }, [usenView, controll]);

  return (
    <div className="hero-container" >
      <div className="container" >
        <div className="hero-main">
          <div className="hero-top">
            <h1>Discover Moii</h1>
            <p className="text2-small">Moii Car Wash is a transparent process where your exteriors and interiors are professionally cleaned</p>
          </div>

          <Card cards={cardsData} />

        </div>

      </div>
    </div>
  );
};

export default Hero;
