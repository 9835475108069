import React, { useState } from "react";
import "./header.css";
import MoiiLogo from "../../assets/LogoMain.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useGlobalData } from "../../context/LanguageContext";
import { Link } from "react-router-dom";
import DownLoadPage from "../../page/Download";

const Header = () => {
  const { languageHandler, languageChange, popus, setPopup } = useGlobalData();

  const [modalToggle, setModalToggle] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [toggle, setToggile] = useState(false);
  const [scoll, setScroll] = useState(false);
  useState(() => {
    const scrollHandler = () => {
      if (window.scrollY > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <header className={`${scoll && "shadow"}`}>
      <div className="logo">
        <img src={MoiiLogo} alt="" />

        <div className={`${toggle === false ? "navLinks" : "navLinkActive"}`}>
          <ul>
            <Link to={"/"} onClick={() => setToggile(false)}>
              <li>{languageChange ? "الرئيسية" : "Home"}</li>
            </Link>
            <Link to={"/terms"} onClick={() => setToggile(false)}>
              {" "}
              <li>{languageChange ? "شروط الاستخدام" : "Terms & Conditions"}</li>
            </Link>
            <Link to={"/privecy"} onClick={() => setToggile(false)}>
              {" "}
              <li>{languageChange ? "سياسة الخصوصية" : "Privacy Policy"}</li>
            </Link>
            {/* <li>About</li> */}
            <li
              onClick={() => {
                setPopup(true);
                setToggile(false);
              }}
            >
              {languageChange ? "تواصل" : "Contact"}
            </li>
            {/* <li>wash</li> */}
            {/* <li>
            <a href="https://play.google.com/store/apps/details?id=com.moiiwash.moii" target="_blank" rel="noopener noreferrer">
              <button className="orangeButton">
                Download App
              </button>
            </a>

          </li> */}
            {/* <li
            onClick={() => {
              languageHandler();
              setToggile(false);
            }}
          >
            {languageChange ? "English" : "عربي"}
          </li> */}
          </ul>
        </div>

        <button
          className="orangeButton float-item"
          onClick={() => setModalOpen(true)} // Open modal on button click
        >
          Download App
        </button>
        {
          modalOpen && (
            <>
              <DownLoadPage isOpen={modalOpen} setIsOpen={setModalOpen} />
            </>
          )
        }
      </div>

      <div className="menu">
        <span onClick={() => setToggile(!toggle)}>
          {toggle ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
        </span>
      </div>
    </header>
  );
};

export default Header;
