import React from "react";
import "./new.css";
import ImgMain from "../../assets/new3.gif";

const New = ({ scrollToHero }) => {
  return (
    <div className="hero-container">
      <div className="container">
        <div className="img1" style={{ backgroundImage: `url(${ImgMain})` }}>
          <p className="text-big float-item">Experience Premium Car Care at Your Doorstep</p>
          <p className="text-small float-item">Introducing Moii Wash, your go-to solution for hassle-free carwashing in the UAE</p>
          <button className="orangebutton float-item" onClick={scrollToHero}>
            Explore Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default New;
